import { GeoResult } from "@/helpers/geo-utils";
import { Worksite } from "../worksite-service/models";

export enum UserStatus {
  APPROVED = "APPROVED",
  TO_BE_APPROVED = "TO_BE_APPROVED",
  BLOCKED = "BLOCKED",
}

export enum ExtraUserStatus {
  ASKED_FOR_DELETION = "ASKED_FOR_DELETION",
}

export enum UserRole {
  APP_ADMIN = "app_admin",
  COMPANY_ADMIN = "company_admin",
  COMPANY_ADMIN_CHECKLIST = "company_admin_checklist",
  COMPANY_ADMIN_DASHBOARD = "company_admin_dashboard",
  INSTALLER = "installer",
  DRIVER = "driver",
  SITE_MANAGER = "site_manager",
  STANDARD_USER = "standard_user",
  LICENCE_MANAGER = "licence_manager",
}

export const MAX_PP_SIZE = 2 * 1024 * 1024; // 2MB

export interface UserForm {
  uuid?: string;
  firstname: string;
  lastname: string;
  companySerial: string;
  phone: string;
  email: string;
  status: UserStatus;
  company: string;
  role: UserRole;
  image: File;
  pincode: string;
  worksites: string[];
}

export class UserData {
  email: string;
  defaultRole?: string;
  phoneNumber?: string;
}

export class ProfileEmailAlert {
  constructor(public checklistError: boolean, public checklistWarning: boolean, public newAccount: boolean, public weeklyReport: boolean) {}
}

export class ProfileData {
  companyId?: string;
  firstName: string;
  lastName: string;
  avatarId?: string;
  companySerial?: string;
  status?: string;
  pincode?: string;
  worksites?: string[];
}

export class User {
  constructor(
    public id: string,
    public status: UserStatus,
    public firstName: string,
    public lastName: string,
    public companySerial: string,
    public email: string,
    public phone: string,
    public role: UserRole,
    public companyName: string,
    public companyId: string,
    public imageId: string,
    public imageName: string,
    public pincode: string,
    public lastSeen: string,
    public tosAccepted: boolean,
    public worksites: Worksite[],
    public createdAt: string
  ) {}

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export interface SignUpFormObject {
  email: string;
  firstname: string;
  lastname: string;
  companyName: string;
  companyAddress: GeoResult;
  phoneNumber: string;
  termsOfServices: boolean;
  requestCode: string;
  code: string;
}

export class CompanyAvailabilityResult {
  nameAvailable: boolean;
  companiesForMerge: { id: string; name; string }[];
  companyCreated: boolean;

  static map(data?: any): CompanyAvailabilityResult | null {
    if (!data) return null;
    const res = new CompanyAvailabilityResult();
    res.nameAvailable = data.name_available;
    res.companiesForMerge = data.companies_for_merge.map((el) => ({ id: el.id, name: el.name }));
    res.companyCreated = data?.company_created || false;
    return res;
  }
}
